<template>
  <div class="icp">
    <div v-show="appName">
      <div class="icp-bg">
        <img class="icp-bg_logo" :src="logoUrl" alt="" srcset="" v-if="logoUrl" />
        <h3>{{ appName }}证照信息</h3>
      </div>
      <div class="icp-content">
        <p>网络文化经营许可证：{{ wangWenNum }}</p>
        <p>
          App 服务备案号：<a @click="handleClick">{{ beiAnNum }}</a>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    components: {},
    props: {},
    name: 'icp',
    data() {
      return {
        appName: '',
        logoUrl: '',
        wangWenNum: '',
        beiAnNum: '',
        appNameMap: new Map([
          [
            'kuaiyin',
            {
              app_name: '快音',
              logo: 'https://static1.kaixinyf.cn/img/lza6554833a77ba5820163432.png',
              wangwen_num: '川网文［2022］3037-031号',
              beian_num: '蜀ICP备19033477号-9A',
            },
          ],
          [
            'kuaiyinyue',
            {
              app_name: '快音悦',
              logo: 'https://static1.kaixinyf.cn/img/lza6554877a03b3e390351072.jpeg',
              wangwen_num: '川网文［2022］3037-031号',
              beian_num: '蜀ICP备19033477号-10A',
            },
          ],
          [
            'kuaiyintingge',
            {
              app_name: '快音听歌',
              logo: 'https://static1.kaixinyf.cn/img/lza655488347be0a710801154.png',
              wangwen_num: '川网文[2020]6023-1180号',
              beian_num: '蜀ICP备2020032222号-3A',
            },
          ],
        ]),
      };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      let app_name = 'kuaiyin';

      if (this.$route.query.app_name) {
        app_name = this.$route.query.app_name;
      } else if (window.bridge && window.bridge.getAppName) {
        app_name = window.bridge.getAppName() || 'kuaiyin';
      }

      this.appName = this.appNameMap.get(app_name).app_name;
      this.logoUrl = this.appNameMap.get(app_name).logo;
      this.wangWenNum = this.appNameMap.get(app_name).wangwen_num;
      this.beiAnNum = this.appNameMap.get(app_name).beian_num;
    },
    methods: {
      handleClick() {
        let data = {
          url: `https://beian.miit.gov.cn/`,
        };

        if (window.bridge && window.bridge.openPageWithJSON) {
          window.bridge.openPageWithJSON(JSON.stringify(data));
        } else {
          window.open(data.url, 'blank');
        }
      },
    },
  };
</script>
<style scoped lang="less">
  .icp {
    min-height: 100vh;
    background-color: #f6f7f9;
    &-bg {
      display: flex;
      align-items: center;
      width: 100%;
      height: 3.8rem;
      background: url('https://static1.kaixinyf.cn//img/lza65547dbb40f2f770800787.png') no-repeat center center;
      &_logo {
        margin: 0 0.3rem;
        width: 1rem;
        height: 1rem;
        border-radius: 0.24rem;
      }
      h3 {
        font-weight: 600;
        font-size: 0.66rem;
      }
    }
    &-content {
      padding: 0.32rem 0.3rem;
      p {
        margin-bottom: 0.32rem;
        font-size: 0.28rem;
        color: #333333;
      }
      a {
        color: #00acfc;
        text-decoration: none;
      }
    }
  }
</style>
